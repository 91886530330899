import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default axios.create({
  //baseURL: 'http://192.168.1.88:8080',
  //baseURL: 'https://192.168.1.88',
  // baseURL: 'https://v.je',
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});
