import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import { App as Navbar } from '../chakraUI/Navbar/App';
import { App as Footer } from '../chakraUI/Footer/App';

const HolyGrail = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const HolyBody = styled.div`
  flex: 1;
`;
const Layout = () => {
  return (
    <HolyGrail>
      <Navbar />
      <HolyBody>
        <Outlet />
      </HolyBody>
      <Footer />
    </HolyGrail>
  );
};

export default Layout;
