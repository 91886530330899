import { Box, Alert, AlertTitle, AlertDescription } from '@chakra-ui/react';

const Error404 = () => {
  return (
    <Box
      maxW="7xl"
      mx="auto"
      px={{
        base: '4',
        md: '8',
        lg: '12',
      }}
      /* py={{
        base: '6',
        md: '8',
        lg: '12',
      }} */
    >
      <Alert status="error">
        <AlertTitle mr={2}>Errore 404</AlertTitle>
        <AlertDescription>La Pagina Richiesta Non Esiste </AlertDescription>
      </Alert>
    </Box>
  );
};

export default Error404;
