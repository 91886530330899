import { Icon, IconButton, LightMode } from '@chakra-ui/react';
import * as React from 'react';

import { FiExternalLink } from 'react-icons/fi';

export const OpenExt = props => {
  return (
    <LightMode>
      <IconButton
        onClick={() =>
          window.open(`${process.env.REACT_APP_BASE_URL}${props.url}`, '_blank')
        }
        isRound
        bg="white"
        color="gray.900"
        size="sm"
        _hover={{
          transform: 'scale(1.1)',
        }}
        sx={{
          ':hover > svg': {
            transform: 'scale(1.1)',
          },
        }}
        transition="all 0.15s ease"
        icon={<Icon as={FiExternalLink} transition="all 0.15s ease" />}
        boxShadow="base"
        title="Apri in Nuova Scheda"
        {...props}
      />
    </LightMode>
  );
};
