import {
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  HStack,
  Stack,
  Checkbox,
  Alert,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';

const SetGroups = ({ onClose, groups, checkValues, setBadges, numSx }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState(checkValues);
  const [error, setError] = useState(false);

  const onChange = id => {
    //console.log(e.target.defaultChecked);
    console.log(id);
    const newValues = [...values];
    newValues.includes(id)
      ? newValues.splice(newValues.indexOf(id), 1)
      : newValues.push(id);
    setValues(newValues);
    console.log(newValues);
  };

  const onSave = async () => {
    setError(false);
    setIsLoading(true);
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.post(
        `/api/set-groups.php?numsx=${numSx}`,
        JSON.stringify(values),
        {
          signal: controller.signal,
        }
      );
      console.log(response.data);

      setBadges(values);
      //setIsLoading(false);
      onClose();

      //setValues(response.data);
    } catch (err) {
      /*   if (err.response.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      } else {
        setIsLoading(false);
        setError(true);
      } */
      setIsLoading(false);
      setError(true);
    }
  };

  return (
    <ModalContent>
      <ModalHeader>Assegna Gruppi</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing="6" direction="column">
          {groups.map(group => {
            return (
              <Checkbox
                key={group.id_groups}
                isChecked={values.includes(group.id_groups)}
                onChange={() => {
                  onChange(group.id_groups);
                }}
              >
                {group.name}
              </Checkbox>
            );
          })}
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button variant="ghost" colorScheme="blue" mr={3} onClick={onClose}>
          Annulla
        </Button>
        <Button variant="outline" onClick={onSave} isLoading={isLoading}>
          Salva
        </Button>
      </ModalFooter>
      {error && <Alert status="error">Errore</Alert>}
    </ModalContent>
  );
};

export default SetGroups;
