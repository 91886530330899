import { Box, Heading, Stack, HStack, Button } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';

export const TestimoneGroup = props => {
  const { title, children, value, ...flexProps } = props;
  const [view, setView] = useState(false);

  useEffect(() => {
    if (value) {
      setView(true);
    }
  }, [value]);

  return (
    <Stack
      direction={{
        base: 'column',
        md: 'row',
      }}
      spacing="6"
      py="4"
      {...flexProps}
    >
      <Box minW="3xs">
        {title && (
          <HStack>
            <Heading
              as="h2"
              fontWeight="semibold"
              fontSize="lg"
              flexShrink={0}
              sx={{
                '@media print': {
                  lineHeight: '0',
                },
              }}
            >
              {title}
            </Heading>
            <Button size="xs" onClick={() => setView(!view)}>
              {view ? <FiMinus /> : <FiPlus />}
            </Button>
          </HStack>
        )}
      </Box>
      {view && children}
    </Stack>
  );
};
