import React from 'react';
//import { App as Login } from './chakraUI/Login2/App';
import { App as Login } from './chakraUI/Login/App';
import { App as Pannello } from './chakraUI/Table/App';
import { App as Form } from './chakraUI/Form/App';
//import { Stampa } from './chakraUI/Form/Stampa';

import { App as Gallery } from './chakraUI/Gallery/App';
import { App as Documents } from './chakraUI/Documents/App';

import Layout from './components/Layout';
import RequireAuth from './components/RequireAuth';
import Error404 from './Error404';

import PersistLogin from './components/PersistLogin';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Routes>
      {/* public routes */}
      <Route path="login" element={<Login />} />

      <Route path="unauthorized" element={<>UNAUTHORIZED</>} />
      {/* <Route path="form" element={<Form />}></Route> */}

      {/* we want to protect these routes */}
      <Route element={<PersistLogin />}>
        <Route path="/" element={<Layout />}>
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Pannello />} />
            <Route path=":numSx" element={<Form />} />
            <Route path="foto/:numSx" element={<Gallery />} />
            <Route path="doc/:numSx" element={<Documents />} />
            {/* <Route path="stampa/:numSx" element={<Stampa />} /> */}
            <Route path="errore404" element={<Error404 />} />
          </Route>
        </Route>
      </Route>
      {/*  <Route element={<RequireAuth allowedRole={ROLES.read} />}>
        <Route path="/" element={<>READONLY</>} />
      </Route> */}

      {/* catch all */}
      <Route path="*" element={<>404</>} />
    </Routes>
  );
}

export default App;
