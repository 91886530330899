const fields = [
  'protocollo',
  'numSx',
  'giornoInc',
  'meseInc',
  'annoInc',
  'giornoSx',
  'meseSx ',
  'annoSx',
  'oraSx ',
  'minSx',
  'sp',
  'comuneSx',
  'luogoSx',
  'noteLuogo',
  'descrSx',
  'autorita',
  'marcaMod',
  'targa',
  'conducente',
  'danni',
  'cognomeInt',
  'nomeInt',
  'codFiscaleInt',
  'indirizzoInt',
  'comuneInt',
  'provInt',
  'telCasaInt',
  'telCellInt',
  'telAltroInt',
  'ragSocialeLeg',
  'nomeLeg',
  'partIvaLeg',
  'codFiscaleLeg',
  'indirizzoLeg',
  'comuneLeg',
  'provLeg',
  'telStudioLeg',
  'telCellLeg',
  'telAltroLeg',
  'emailLeg',
  'tipoFer1',
  'visitaFer1',
  'cognomeFer1',
  'nomeFer1',
  'codFiscaleFer1',
  'legaleFer1',
  'partIvaLeg1',
  'codFiscaleLeg1',
  'telLeg1',
  'emailLeg1',
  'franchigia',
  'contenzioso',
  'noteFinali',
];

export const initialValues = fields.reduce((o, i) => ({ ...o, [i]: '' }), {});

//fields.reduce((o, i) => ({ ...o, id: [i] }), {});

/* export const initialValues = {
  annoInc: '',
  annoSx: '',
  autorita: '',
  codFiscaleFer1: '',
  codFiscaleInt: '',
  codFiscaleLeg: '',
  codFiscaleLeg1: '',
  cognomeFer1: '',
  cognomeInt: '',
  comuneInt: '',
  comuneLeg: '',
  comuneSx: '',
  conducente: '',
  contenzioso: '',
  danni: '',
  descrSx: '',
  emailLeg: '',
  emailLeg1: '',
  franchigia: '',
  giornoInc: '',
  giornoSx: '',
  indirizzoInt: '',
  indirizzoLeg: '',
  legaleFer1: '',
  luogoSx: '',
  marcaMod: '',
  meseInc: '',
  meseSx: '',
  minSx: '',
  nomeFer1: '',
  nomeInt: '',
  nomeLeg: '',
  noteFinali: '',
  noteLuogo: '',
  numSx: '666',
  oraSx: '',
  partIvaLeg: '',
  partIvaLeg1: '',
  protocollo: 'oppo',
  provInt: '',
  provLeg: '',
  ragSocialeLeg: '',
  sp: '',
  targa: '',
  telAltroInt: '',
  telAltroLeg: '',
  telCasaInt: '',
  telCellInt: '',
  telCellLeg: '',
  telLeg1: '',
  telStudioLeg: '',
  tipoFer1: 'Trasportato',
  visitaFer1: '',
}; */
