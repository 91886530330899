import { HStack, Button, Text, Link, Icon } from '@chakra-ui/react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FiXCircle } from 'react-icons/fi';
import { DeleteButton } from './DeleteButton';

import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';

const Document = ({ doc, onOpen, setUrl, setRefresh, isAdmin }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const openSetUrl = () => {
    setUrl(doc.url);
    onOpen();
  };

  const deleteFile = async () => {
    try {
      const res = await axiosPrivate.delete(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/delete-doc.php?url=${encodeURIComponent(doc.url)}`
      );

      console.log(res.data);
      setRefresh({});
    } catch (err) {
      /* if (err.response.status === 400) {
            setErrorMessage(err.response.data.message);
          } else {
            navigate('/login', { state: { from: location }, replace: true });
          } */
      navigate('/login', { state: { from: location }, replace: true });
      console.log(err.response.status);
    }
  };

  return (
    <HStack>
      <Icon as={AiOutlineFilePdf} fontSize="1.25em" color="blue.400" />
      {/* <AiOutlineFilePdf fontSize="1.25em" color="blue" /> */}
      <Link>
        <Text fontSize="lg" onClick={openSetUrl}>
          {doc.name.split('.pdf')[0]}
        </Text>
      </Link>
      {isAdmin && <DeleteButton deleteFile={deleteFile} />}
    </HStack>
  );
};

export default Document;
