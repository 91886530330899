import { useState, useRef } from 'react';
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Input,
  Stack,
  Text,
  // useDisclosure,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export const DeleteButton = ({ isOpen, onClose, setRefresh, deleteProps }) => {
  //const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);

  const [isConfirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const deleteSx = async () => {
    setError(false);
    setIsLoading(true);
    //const controller = new AbortController();
    console.log(deleteProps);
    try {
      const response = await axiosPrivate.delete('/api/delete.php', {
        data: JSON.stringify(deleteProps),
      });
      console.log(response.data);
      onClose();
      setIsLoading(false);
      setRefresh({});
      setIsLoading(false);

      //setValues(response.data);
    } catch (err) {
      if (err.response.status === 403) {
        navigate('/login', { state: { from: location }, replace: true });
      }
      setIsLoading(false);
      setError(true);
    }
  };

  return (
    <>
      {/* <Button size="sm" variant="ghost" color="red" onClick={onOpen}>
        {title}
      </Button> */}

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Elimina Sinistro {deleteProps.numSx}
            </AlertDialogHeader>

            <AlertDialogBody>
              <Stack spacing={3}>
                <Text>Tutti i dati e i file allegati saranno cancellati!</Text>
                <Text>Inserisci il numero Sinistro per confermare</Text>

                <Input
                  w="7rem"
                  size="sm"
                  onChange={e => {
                    if (e.target.value === deleteProps.numSx) {
                      setConfirmed(true);
                    }
                  }}
                />
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  setConfirmed(false);
                  onClose();
                }}
              >
                Annulla
              </Button>
              <Button
                colorScheme="red"
                onClick={deleteSx}
                ml={3}
                isDisabled={!isConfirmed}
                isLoading={isLoading}
              >
                Elimina
              </Button>
              {error && <Alert status="error">Errore</Alert>}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
